/* eslint-disable angular/no-run-logic */
/* eslint-disable angular/component-limit */
'use strict';

angular
    .module('study', [
        'ngAnimate',
        'ngCookies',
        'ngMessages',
        'ngSanitize',
        'app.config',
        'app.footer',
        'app.front',
        'app.notifications',
        'body-layout',
        'inetsys.datepicker',
        'inetsys.input-number',
        'inetsys.messages',
        'inetsys.modal',
        'inetsys.tools',
        'inetsys.utils',
        'inlineHelp',
        'link-disabled',
        'LocalStorageModule',
        'ngFileUpload',
        'page-title',
        'security-redirect',
        'security-redirect-autoreload-session',
        'sharecrf-auth',
        'sharecrf-auth.multiprofile-interceptor',
        'sharecrf-sdk',
        'sharecrf.common',
        'sharecrf.cookies',
        'sharecrf.date-format',
        'sharecrf.environment',
        'sharecrf.gtm',
        'sharecrf.internal-value',
        'sharecrf.loading',
        'sharecrf.login-info',
        'sharecrf.multiple',
        'sharecrf.news',
        'sharecrf.partial-date',
        'sharecrf.progress-status',
        'sharecrf.radios',
        'sharecrf.utils',
        'sharecrf.viewport',
        'study-info',
        'study.dashboard',
        'study-environment',
        'study-environment-interceptor',
        'study.ecrd',
        'study.error',
        'study.export',
        'study.i18n',
        'study.login',
        'study.menu',
        'study.placeholders',
        'toaster',
        'trNgGrid',
        'ui.router',
    ])
    .config(['BodyLayoutProvider', function(BodyLayoutProvider) {
        BodyLayoutProvider.setLayout('frontpage', ['front', 'info']);
        BodyLayoutProvider.setLayout('editor', [
            'record.edit.data',
            'record.edit.data.*',
        ]);
        BodyLayoutProvider.setLayout('record-randomization', ['record.edit.randomization']);
        BodyLayoutProvider.setLayout('record-notifications', ['record.edit.notifications']);
        BodyLayoutProvider.setLayout('record-certcopy', ['record.edit.certcopy']);
        BodyLayoutProvider.setLayout('record-epro', ['record.edit.epro', 'record.edit.epro.*']);
        BodyLayoutProvider.setLayout('record-reminders', ['record.edit.reminders', 'record.edit.reminders.*']);
    }])
    // Init service with user in session
    .run(['AccountManager', 'Event', '$state', 'SecurityRedirect', function(AccountManager, Event, $state, SecurityRedirect) {
        Event.on('accountInitied', function() {
            var loggedUser = AccountManager.getLoggedUser();

            if (loggedUser.inStudy === false) {
                $state.go('error', {
                    type: 'StudyUserError',
                    code: 'user-not-in-study',
                });
            }
            else if (loggedUser.blocked) {
                $state.go('error', {
                    type: 'StudyUserError',
                    code: 'study-user-blocked',
                });
            }
            else if (loggedUser.id && loggedUser.currentProfile) {
                var hasProfile = Array.isArray(loggedUser.profiles) && !!loggedUser.profiles.find(function(profile) {
                    return profile.id === loggedUser.currentProfile.id;
                });

                if (!hasProfile) {
                    $state.go('error', {
                        type: 'StudyUserError',
                        code: 'environment-access-denied',
                    });
                }
            }
        });

        SecurityRedirect.init();
        AccountManager.init();
    }])
    // Configure SDK
    .config([
        '$windowProvider',
        'ShareCrfSDKProvider',
        'MultiprofileProvider',
        'StudyEnvironmentProvider',
        function ($windowProvider, ShareCrfSDKProvider, MultiprofileProvider, StudyEnvironmentProvider) {
            var $window = $windowProvider.$get();
            var studySubdomain = $window.ShareCRF.studySubdomain;
            var studyEnvironment = $window.ShareCRF.environment;

            ShareCrfSDKProvider
                .setBaseUrl('/' + studyEnvironment + '/api')
                .setOrigin('Study-ShareCRF@APP_VERSION')
                .setStudyRelativeUrls()
                .setStudy(studySubdomain)
                .setEnvironment(studyEnvironment)
                .setHeaders({
                    'X-ShareCRF-Raw': 'study', // @see server/study/controllers/records.js:266)
                });

            MultiprofileProvider.cookiePath = '/' + studyEnvironment + '/';

            StudyEnvironmentProvider.set(studyEnvironment);

            if (angular.isDefined($window.jstz)) {
                ShareCrfSDKProvider.setTimeZone($window.jstz.determine().name());
            }
        },
    ])
    // GARU-4642 Date formats
    // GARU-4785 Día de inicio del calendario en función del idioma del usuario
    .config([
        'ShareCrfDateFormatProvider',
        'InetsysDatepickerConfigProvider',
        '$windowProvider',
        function(ShareCrfDateFormatProvider, InetsysDatepickerConfigProvider, $windowProvider) {
            // Lista de países donde la semana empieza en domingo
            var countriesStartingInSunday = ['JP', 'CA', 'US'];
            angular.injector(['ngCookies']).invoke(['$cookies', function($cookies) {
                var weekStartDay = 1; // Lunes por defecto
                if (countriesStartingInSunday.indexOf($cookies.get('COUNTRY')) !== -1) {
                    weekStartDay = 0;
                }
                InetsysDatepickerConfigProvider.options.weekStartDay = weekStartDay;
            }]);

            ShareCrfDateFormatProvider
                // Representación de un timestamp en front (más corto o largo)
                // Por ejemplo:
                //    - fecha de creación de un paciente en el listado de pacientes
                //    - fecha de creación de una query
                //    - fecha de un cambio en el audit trail
                .addFormat('timeshort', 'lll')
                .addFormat('timelong', 'LLL')
                // Formato unívoco para dato de paciente en variables de tipo fecha
                .addFormat('dateinput', 'DD-MMM-YYYY')
                .addFormat('datetimeinputminutes', 'DD-MMM-YYYY HH:mm')
                .addFormat('datetimeinputseconds', 'DD-MMM-YYYY HH:mm:ss');

            var $window = $windowProvider.$get();
            if (angular.isDefined($window.jstz)) {
                ShareCrfDateFormatProvider.setDefaultTimezone($window.jstz.determine().name());
            }
        },
    ])
    // Define service to manage authentication in routes
    .config(['RouteAuthProvider', function(RouteAuthProvider) {
        RouteAuthProvider.init = ['AccountManager', function(AccountManager) {
            return AccountManager.init;
        }];
        RouteAuthProvider.getLoggedUser = ['AccountManager', function(AccountManager) {
            return AccountManager.getLoggedUser;
        }];
        RouteAuthProvider.isLogged = ['AccountManager', function(AccountManager) {
            return AccountManager.isLogged;
        }];
        RouteAuthProvider.hasPermission = ['AccountManager', function(AccountManager) {
            return AccountManager.hasPermission;
        }];
    }])
    // GARU-3965 Forzar el punto como separador decimal en campos de tipo numérico
    .config(['InetsysNumberProvider', function(InetsysNumberProvider) {
        // Se fuerza el idioma de los número en inglés para que se formateen con el punto decimal
        InetsysNumberProvider.useLocale('en');
        // Solamente se permite el punto como separador decimal en la entrada de datos
        InetsysNumberProvider.numberPattern = /^(- *)?[0-9]+(\.[0-9]+)?(e[+-]?[0-9]+)?$/;
    }])
    .run(['$state', 'StudyInfo', 'PageTitle', function($state, StudyInfo, PageTitle) {
        StudyInfo.init()
            .catch(function(error) {
                $state.go('error', error);

                return {};
            })
            .then(function () {
                var studyInfo = StudyInfo.getInfo();
                PageTitle.setBrand(studyInfo.acronym);
            });
    }])
    // Session management
    .config(['SecurityRedirectProvider', '$windowProvider', function(SecurityRedirectProvider, $windowProvider) {
        var $window = $windowProvider.$get();
        var studyEnvironment = $window.ShareCRF.environment;
        SecurityRedirectProvider.url =  '/' + studyEnvironment + '/login';
    }])
    // Define action to execute after clicking in logout button
    .config(['LogoutManagerProvider', '$windowProvider', function(LogoutManagerProvider, $windowProvider) {
        var $window = $windowProvider.$get();
        LogoutManagerProvider.onLogout = ['$state','$q', function($state) {
            $window.location = '/' + $window.ShareCRF.environment + '/login?message=successful-logout';
        }];
    }])
    // Body class by state
    .config(['BodyLayoutProvider', function(BodyLayoutProvider) {
        // Body class
        BodyLayoutProvider.setLayout('clean', [
            'login',
        ]);
    }])
    // Configure trNgGrid templates
    .run(['$window', '$templateCache', function($window, $templateCache) {
        if ($window.TrNgGrid) {
            // Default settings of TrNgGrid
            $window.TrNgGrid.columnSortCssClass = 'sortable';
            $window.TrNgGrid.columnSortNormalOrderCssClass = 'order-asc';
            $window.TrNgGrid.columnSortReverseOrderCssClass = 'order-desc';
            $window.TrNgGrid.defaultPagerMinifiedPageCountThreshold = 5;

            $window.TrNgGrid.footerCssClass = '';
            $window.TrNgGrid.headerCellCssClass = '';
            $window.TrNgGrid.tableCssClass = '';
            $window.TrNgGrid.cellCssClass = '';
            $window.TrNgGrid.bodyCellCssClass = '';
            $window.TrNgGrid.columnTitleCssClass = '';

            // Simplified header cell template with sorting supported
            $templateCache.put($window.TrNgGrid.cellHeaderTemplateId, '' +
                '<div ng-switch="gridOptions.enableSorting || columnOptions.enableSorting">' +
                    '<div ng-switch-when="true" ng-click="toggleSorting(columnOptions.fieldName)" ng-class="{' +
                        '\'' + $window.TrNgGrid.columnSortCssClass + '\': true,' +
                        '\'' + $window.TrNgGrid.columnSortNormalOrderCssClass + '\': gridOptions.orderBy == columnOptions.fieldName && !gridOptions.orderByReverse,' +
                        '\'' + $window.TrNgGrid.columnSortReverseOrderCssClass + '\': gridOptions.orderBy == columnOptions.fieldName && gridOptions.orderByReverse,' +
                    '}">' +
                        '<div ng-if="isCustomized"><div ng-transclude=""></div></div>' +
                        '<div ng-if="!isCustomized">{{ columnTitle |' + $window.TrNgGrid.translateFilter + ' : gridOptions.locale }}</div>' +
                    '</div>' +
                    '<div ng-switch-default>' +
                        '<div ng-if="isCustomized"><div ng-transclude=""></div></div>' +
                        '<div ng-if="!isCustomized">{{ columnTitle |' + $window.TrNgGrid.translateFilter + ' : gridOptions.locale }}</div>' +
                    '</div>' +
                '</div>'
            );

            // Pagination template
            $templateCache.put($window.TrNgGrid.footerPagerTemplateId, '' +
                '<div class="table-pager">' +
                '    <ul class="pagination">' +
                '        <li ng-if="extendedControlsActive">' +
                '            <button type="button" class="nav-first" ng-disabled="!pageCanGoBack" ng-click="pageCanGoBack && navigateToPage(0)">' +
                '                <span class="tooltip">{{ \'First Page\'|' + $window.TrNgGrid.translateFilter + ':gridOptions.locale }}</span>' +
                '            </button>' +
                '        </li>' +
                '        <li ng-if="extendedControlsActive">' +
                '            <button type="button" class="nav-prev" ng-disabled="!pageCanGoBack" ng-click="pageCanGoBack && navigateToPage(gridOptions.currentPage - 1)">' +
                '                <span class="tooltip">{{ \'Previous Page\'|' + $window.TrNgGrid.translateFilter + ':gridOptions.locale }}</span>' +
                '            </button>' +
                '        </li>' +
                '        <li ng-if="pageSelectionActive" ng-repeat="pageIndex in pageIndexes track by $index">' +
                '            <span class="nav-more" ng-if="pageIndex === null">...</span>' +
                '            <button type="button" class="nav-page" ng-click="navigateToPage(pageIndex)" ng-if="pageIndex !== null" ng-class="{active: pageIndex === gridOptions.currentPage}">' +
                '                {{ pageIndex + 1 }}' +
                '                <span class="tooltip">{{ \'Page\'|' + $window.TrNgGrid.translateFilter + ':gridOptions.locale}} {{ pageIndex + 1 }}</span>' +
                '            </button>' +
                '        </li>' +
                '        <li ng-if="extendedControlsActive">' +
                '            <button type="button" class="nav-next" ng-disabled="!pageCanGoForward" ng-click="pageCanGoForward && navigateToPage(gridOptions.currentPage + 1)">' +
                '                <span class="tooltip">{{ \'Next Page\'|' + $window.TrNgGrid.translateFilter + ':gridOptions.locale }}</span>' +
                '            </button>' +
                '        </li>' +
                '        <li ng-if="extendedControlsActive">' +
                '            <button type="button" class="nav-last" ng-disabled="!pageCanGoForward" ng-click="pageCanGoForward && navigateToPage(lastPageIndex)">' +
                '                <span class="tooltip">{{ \'Last Page\'|' + $window.TrNgGrid.translateFilter + ':gridOptions.locale }}</span>' +
                '            </button>' +
                '        </li>' +
                '    </ul>' +
                '    <div class="item-counter">' +
                '        <span ng-if="!totalItemsCount">{{ \'No items to display\'|' + $window.TrNgGrid.translateFilter + ':gridOptions.locale }}</span>' +
                '        <span ng-if="totalItemsCount">' +
                '           {{ startItemIndex + 1 }} - {{ endItemIndex + 1 }} {{ \'displayed\'|' + $window.TrNgGrid.translateFilter + ':gridOptions.locale }},' +
                '           {{ totalItemsCount }} {{ \'in total\'|' + $window.TrNgGrid.translateFilter + ':gridOptions.locale }}' +
                '        </span>' +
                '    </div>' +
                '</div>'
            );

        }
    }])

    .run(['$rootScope', 'StudyLanguageService', function($rootScope, StudyLanguageService) {
        StudyLanguageService.initialize();
        $rootScope.StudyLanguage = StudyLanguageService.language;
    }])
    // Activar HTML5
    .config(['$locationProvider', function($locationProvider) {
        $locationProvider.html5Mode(true);
    }])
    .run(['$rootScope', '$animate', function($rootScope, $animate) {
        $rootScope.enable_tooltips = true;
        $rootScope.whereami = 'app';
        $animate.enabled(false);
    }])
    .run(['$templateCache', 'gettextCatalog', function($templateCache, gettextCatalog) {
        // GARU-3122 Plantillas de ayuda
        $templateCache.put('templates/inline-help-button.html',
            '<button type="button" class="inline-help-trigger" ng-click="toggle()">' + gettextCatalog.getString('Ayuda') + '</button>\n' +
            '');
        $templateCache.put('templates/inline-help.html',
            '<div ng-if="inlineHelpIsShown" class="inline-help-panel">\n' +
            '    <button type="button" class="inline-help-close" ng-click="inlineHelpToggle()" title="' + gettextCatalog.getString('Ocultar ayuda') + '">' + gettextCatalog.getString('Cerrar') + '</button>\n' +
            '    <h4>' + gettextCatalog.getString('Ayuda') + '</h4>\n' +
            '    <div class="inline-help-content" ng-transclude></div>\n' +
            '</div>\n' +
            '');
        // GARU-3405 Plantilla datepicker
        $templateCache.put('templates/inetsys-calendar.html',
            '<div class="inetsys-calendar" ng-if="calendar.isVisible()">\n' +
            '    <div class="inetsys-calendar-years" ng-if="calendar.mode === \'year\'">\n' +
            '        <ul class="years">\n' +
            '            <li class="nav-prev"><button type="button" ng-click="calendar.showPreviousYears()">' + gettextCatalog.getString('Años anteriores') + '</button></li>\n' +
            '            <li ng-repeat="year in calendar.years" ng-class="{selected: year.selected}">\n' +
            '                <button type="button" ng-click="calendar.selectYear(year)">{{ year.id }}</button>\n' +
            '            </li>\n' +
            '            <li class="nav-next"><button type="button" ng-click="calendar.showNextYears()">' + gettextCatalog.getString('Años siguientes') + '</button></li>\n' +
            '        </ul>\n' +
            '    </div>\n' +
            '    <div class="inetsys-calendar-months" ng-if="calendar.mode === \'month\'">\n' +
            '        <ul class="inetsys-calendar-nav">\n' +
            '            <li class="nav-prev"><button type="button" ng-click="calendar.showPrevYear()">' + gettextCatalog.getString('Año anterior') + '</button></li>\n' +
            '            <li><button type="button" ng-click="calendar.setMode(\'year\')">{{ calendar.currentDate.format(\'YYYY\') }}</button></li>\n' +
            '            <li class="nav-next"><button type="button" ng-click="calendar.showNextYear()">' + gettextCatalog.getString('Año siguiente') + '</button></li>\n' +
            '        </ul>\n' +
            '        <ul class="year-months">\n' +
            '            <li ng-repeat="month in calendar.months" ng-class="{selected: month.selected}">\n' +
            '                <button type="button" ng-click="calendar.selectMonth(month)">{{ ::month.label }}</button>\n' +
            '            </li>\n' +
            '        </ul>\n' +
            '    </div>\n' +
            '    <div class="inetsys-calendar-days" ng-if="calendar.mode === \'day\'">\n' +
            '        <ul class="inetsys-calendar-nav">\n' +
            '            <li class="nav-prev"><button type="button" ng-click="calendar.showPrevMonth()">' + gettextCatalog.getString('Mes anterior') + '</button></li>\n' +
            '            <li><button type="button" ng-click="calendar.setMode(\'month\')">{{ calendar.currentDate.format(\'MMMM\') }} {{ calendar.currentDate.format(\'YYYY\') }}</button></li>\n' +
            '            <li class="nav-next"><button type="button" ng-click="calendar.showNextMonth()">' + gettextCatalog.getString('Mes siguiente') + '</button></li>\n' +
            '        </ul>\n' +
            '        <ul class="month-days">\n' +
            '            <li ng-repeat="weekDay in ::calendar.weekDays"><strong>{{ ::weekDay }}</strong></li>\n' +
            '            <li ng-repeat="day in calendar.days" ng-class="{selected: day.selected, today: day.today, \'prev-month\': day.prevMonth, \'next-month\': day.nextMonth, disabled: day.disabled}">\n' +
            '                <button type="button" ng-click="calendar.selectDay(day)" ng-disabled="::day.disabled">{{ ::day.id.format(\'DD\') }}</button>\n' +
            '            </li>\n' +
            '        </ul>\n' +
            '    </div>\n' +
            '    <div class="inetsys-calendar-footer">\n' +
            '        <button type="button" class="clear" ng-click="calendar.clearDate()">' + gettextCatalog.getString('Borrar') + '</button>\n' +
            '        <button type="button" class="today" ng-click="calendar.selectToday()">' + gettextCatalog.getString('Hoy') + '</button>\n' +
            '        <button type="button" class="close" ng-click="calendar.closeCalendar()">' + gettextCatalog.getString('Cerrar') + '</button>\n' +
            '    </div>\n' +
            '</div>\n' +
            '');
    }])

    .directive('ngIfOnce', function() {
        return {
            restrict: 'A',
            transclude: true,
            template: '<span ng-show="originalNgIf" ng-if="initialized" ng-transclude></span>',
            scope: {
                ngIfOnce: '=',
            },
            link: function(scope, attr) {
                scope.$watch('ngIfOnce', function(newVal) {
                    scope.originalNgIf = newVal;

                    if (angular.isUndefined(undefined) && scope.originalNgIf === true) {
                        scope.initialized = true;
                    }
                });
            },
        };
    })

    // New stuff used in build process /////////////////////////////////////////////////////////////////

    .filter('default', function() {
        return function(value, defaultValue) {
            return value || defaultValue;
        };
    })
    // Source: http://stackoverflow.com/a/22535765
    .filter('striptags', function() {
        return function strip_tags(input, allowed) {
            if (!input) return input;
            allowed = (((allowed || '') + '')
                .toLowerCase()
                .match(/<[a-z][a-z0-9]*>/g) || [])
                .join(''); // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
            var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
                commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;

            return input.replace(commentsAndPhpTags, '')
                .replace(tags, function($0, $1) {
                    return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
                });
        };
    });
